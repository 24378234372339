<!-- 首页 -->
<template>
    <div class="page-trendCircle f-wrap">
        <div class="trend-tab">
            <el-tabs v-model="curr_id" @tab-click="handleClick">
                <el-tab-pane :label="item.name" :name="String(item.id)" v-for="item in typeArray" :key="item.id">
                    <circle-list v-if="curr_id==item.id" :parmas="parmas" ></circle-list>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { indexApi } from "@/api/module.js";
import circleList from '../components/circlelist'
export default {
    components: {
        circleList
    },
    name: "trendCircle",
    data(){
        return{
            typeArray:[],   //商品分类
            curr_id:"1",
            parmas:{
                api:'/api/method/getDynamic',
                data:{
                    zone_id:0,
                    size:10,
                    goods_class_id:2,
                    type:2,
                    brand_id:0
                }
            }
        }
    },
    created(){
        //获取商品分类getGoodsClass
        indexApi
        .getGoodsClass({})
        .then((res) => {
            if (res.data.data) {
                this.typeArray = res.data.data;
                this.curr_id = String(this.typeArray[0].id);
            }
        });
    },
    methods:{
        //tab切换
        handleClick(){
            this.parmas.data.goods_class_id = this.curr_id;
        }
    }
};
</script>

<style lang="less" scoped>
.home-group {
    font-size: 0.2rem;
    max-width: 1200px;
    margin: 0 auto;
    height: 3.2rem;
    border: 1px solid #f00;
}
.trend-tab{
}
</style>
<style lang="less">
body{
    background-color: #F1F1F1;
}
 /* 重置el样式 */
 .page-trendCircle{
     .el-tabs__item{
         font-family: PingFang SC;
     }
     .el-tabs__active-bar{
         height: 3px;
         border-radius: 2px;
        background-color: #F02420;
    }
    .el-tabs__item.is-active{
        color: white;
        background-color:#F02420 ;
    }
    .el-tabs__nav-wrap::after{
        background-color:#F02420 ;
    }
    .el-tabs__header{
        margin: 30px 0 26px;
    }
    .el-tabs--top .el-tabs__item.is-top:nth-child(2){
        padding-left: 20px;
    }
    .el-tabs--top .el-tabs__item.is-top:last-child{
        padding-right: 20px;
    }
    .el-tabs__item:hover{
        color: #F02420;
    }
    .el-tabs__item.is-active:hover{
        color: white;
    }
    /* 线条移动动效去除 */
    .el-tabs__active-bar{
        transition-duration: 0s;
    }
    .el-tabs__nav-prev,
    .el-tabs__nav-next{
        display: none;
    }
 }
//移动处理
@media screen and (max-width:750px) {
    body{
        background: #fff;
    }
    .page-trendCircle{
        .el-tabs__item{
            color: #B5B5B5;
        }
        .el-tabs__nav-wrap{
            padding-bottom: 5px;
        }
        .el-tabs__header{
            margin: 0;
            background-color: #EEEEEE;
        }
        .el-tabs__item.is-active{
            background-color: transparent;
            color: #000000;
            font-weight: bold;
            font-size: 18px;
        }
        .el-tabs__item.is-active:hover{
            color: #000000;
            font-weight: bold;
        }
        .el-tabs__nav-wrap::after{
            background-color:transparent ;
        }
    }
}
</style>
